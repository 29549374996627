import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { XCircleIcon, CheckCircleIcon, MicrophoneIcon, ShieldCheckIcon, AdjustmentsHorizontalIcon, PuzzlePieceIcon, ChatBubbleBottomCenterTextIcon, LightBulbIcon } from '@heroicons/react/20/solid'

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";

const storypoints = [
  {
    name: 'Every writer gets stuck.',
    description:
      'Writing is about weaving together barely-formed thoughts into something cohesive and powerful. These thoughts can find you anywhere, but rarely are they convenient enough to find you sitting at your desk.',
    icon: XCircleIcon,
    color: 'text-red-600',
  },
  {
    name: 'Transform thoughts into polished writing.',
    description: 'Dump rambling thoughts. Echo will organize and polish it for you, providing suggested text and edits to get you back to writing.',
    icon: CheckCircleIcon,
    color: 'text-emerald-600',
  }
]

const features = [
  {
    name: 'Voice-to-text.',
    description: 'Quickly capture your thoughts by speaking naturally. Echo transcribes your voice memos into written text, so you can effortlessly translate spoken ideas into a usable draft.',
    icon: MicrophoneIcon,
  },
  {
    name: 'Private and secure.',
    description: 'Rest assured, your notes are private. Your data is encrypted, we don\'t look at it, and it is not used to train any AI.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Auto organization.',
    description: 'Echo uses GPT-4o to organize your thoughts.',
    icon: AdjustmentsHorizontalIcon,
  },
  {
    name: 'Seamless integration.',
    description: 'The Echo iphone app combines with add-ons for your favorite writing tools.',
    icon: PuzzlePieceIcon,
  },
  {
    name: 'Context-aware.',
    description: 'Talk to Echo\'s AI about your writing and your notes and never worry about repeating yourself.',
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: 'Idea synthesis.',
    description: 'Echo combines your fragmented thoughts and ideas into a unified, coherent narrative.',
    icon: LightBulbIcon,
  },
]

export default function WritingPage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/writing",
      title: "Writing Use-Case Page",
    });
  }, []);

  return (
    <div>
      <PublicHeader />

      {/* Hero and Problem Statement */}
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          aria-hidden="true"
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <div className="max-w-2xl lg:col-span-2 xl:col-auto">
              <div className="inline-flex mb-8">
                <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                  Coming soon
                </span>
              </div>
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Get unstuck when writing by talking out loud
              </h1>
            </div>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                Use Echo voice notes to clarify your thoughts faster and capture lightning when it strikes.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {storypoints.map((storypoint) => (
                  <div key={storypoint.name} className="relative pl-9">
                    <dt className={`inline font-semibold ${storypoint.color}`}> {/* Apply the color to the title */}
                      <storypoint.icon
                        aria-hidden="true"
                        className={`absolute left-1 top-1 h-5 w-5 ${storypoint.color}`}
                      />
                      {storypoint.name}
                    </dt>{' '}
                    <dd className="inline">{storypoint.description}</dd>
                  </div>
                ))}
              </dl>
              <div className="mt-10 flex items-center gap-x-6">
                <a href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019" target="_blank" rel="noopener noreferrer">
                  <img
                    src="/app-store-button.png"
                    alt="Download on the App Store"
                    className="w-32 mx-auto sm:mx-0"
                  />
                </a>
                <a
                  href="/"
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div>
            </div>
            <img
              alt=""
              src="/illustration-talking-notes.png"
              className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>

      {/* Showcase Section */}
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">You talk, we organize</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Integrate voice notes with your writing</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Wherever your thoughts strike, capture them and bring them into your writing. Use Echo's AI to structure your thoughts into something useful.
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto relative max-w-7xl px-6 lg:px-8">
          <img
            alt="App screenshot"
            src="/showcase-gdoc.png"
            width={2432}
            height={1442}
            className="mb-4 lg:mb-[-4%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
          />
          {/* Overlay smaller iPhone image */}
          <div className="absolute top-36 left-32 transform -translate-x-1/2 w-[300px] hidden lg:block">
            <img
              alt="iPhone screenshot"
              src="/iphone-mock-recording.png"
              className="w-full mb-4 rounded-xl"
            />
          </div>
          <div aria-hidden="true" className="relative">
            <div className="absolute -inset-x-24 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>

      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
      
      <Footer />
    </div>
  );
}
