import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";


export default function ChroniclePage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/chronicle",
      title: "Chronicle Use-Case Page",
    });
  }, []);

  return (
    <div>
      <PublicHeader />

        {/* Hero */}
        <div className="mx-auto max-w-2xl px-6 py-32 sm:py-48 lg:py-40">
          <svg
            aria-hidden="true"
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
          </svg>
          <div className="text-center">
            <div className="inline-flex mb-8">
              <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                Coming soon
              </span>
            </div>
            <img 
              src="illustration-capture-random.png" 
              alt="Illustration Capture" 
              className="mx-auto mb-4 w-5/12 h-auto"
            />
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Chronicle your life
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Combine your thoughts and photos to create a rich tapestry of your life. Photos allow you to capture what's happening, Echo voice notes allow you to capture everything else.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019" target="_blank" rel="noopener noreferrer">
                <img
                  src="/app-store-button.png"
                  alt="Download on the App Store"
                  className="w-32 mx-auto sm:mx-0"
                />
              </a>
              <a href="/" className="text-sm font-semibold leading-6 text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>

        {/* How it works section 1 */}
        <div className="mx-auto max-w-5xl px-6 py-6 flex flex-col lg:flex-row justify-between items-center lg:px-8 lg:py-30">
          <div className="w-full lg:w-[45%]">
            <p className="text-base font-semibold leading-7 font-primary-blue">Step 1</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Take photos</h1>
            <p className="mt-6 text-base leading-7 text-gray-700">
              Keep taking photos as you always do. For special events or everyday observations, Echo helps you enhance the photo with more context.
            </p>
          </div>
          <div className="w-full lg:w-[45%]">
            <div className="px-12">
              <img
                alt=""
                src="/illustration-selfie.png"
                className="w-full max-w-full"
              />
            </div>
          </div>
        </div>

        {/* How it works section 2 */}
        <div className="mx-auto max-w-5xl px-6 py-6 sm:py-10 flex flex-col lg:flex-row justify-between items-center lg:px-8 lg:py-30">
          <div className="hidden lg:block lg:w-[45%]">
            <div className="px-12">
            <svg
                viewBox="0 0 366 729"
                role="img"
                className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              >
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img src="/iphone-mock-recording-memories.png" alt="" />
                </foreignObject>
            </svg>
            </div>
          </div>
          <div className="w-full lg:w-[45%]">
            <p className="text-base font-semibold leading-7 font-primary-blue">Step 2</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Add context</h1>
            <p className="mt-6 text-base leading-7 text-gray-700">
              Take quick voice notes to capture the story behind the moment. Echo will transcribe, organize, and even help weave together rambling thoughts.
            </p>
            <div className="mt-20">
              <img
                alt=""
                src="/illustration-talking-notes.png"
                className="w-full max-w-full"
              />
            </div>
          </div>
        </div>

        {/* How it works section 3 */}
        <div className="mx-auto max-w-5xl px-6 py-6 flex flex-col lg:flex-row justify-between items-center lg:px-8 lg:py-30">
          <div className="w-full lg:w-[45%]">
            <p className="text-base font-semibold leading-7 font-primary-blue">Step 3</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Enjoy your memories</h1>
            <p className="mt-6 text-base leading-7 text-gray-700">
              Relive your memories in vivid detail. With Echo, you’re not just revisiting photos; you’re revisiting moments, complete with your personal reflections. It’s more than just a visual reminder—it’s the story, the emotion, and the context that bring those memories to life. Your catalogue of memories becomes deeper and more meaningful than anything Apple’s default memories feature could offer.
            </p>
          </div>
          <div className="hidden lg:block lg:w-[45%]">
            <div className="px-12">
              <svg
                  viewBox="0 0 366 729"
                  role="img"
                  className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
                >
                  <title>App screenshot</title>
                  <defs>
                    <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                      <rect width={316} height={684} rx={36} />
                    </clipPath>
                  </defs>
                  <path
                    fill="#4B5563"
                    d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                  />
                  <path
                    fill="#343E4E"
                    d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                  />
                  <foreignObject
                    width={316}
                    height={684}
                    transform="translate(24 24)"
                    clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                  >
                    <img src="/iphone-mock-journal-feed.png" alt="" />
                  </foreignObject>
              </svg>
            </div>
          </div>
        </div>

      <Footer />
    </div>
  );
}
