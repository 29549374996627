import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import {
  MicrophoneIcon,
  LightBulbIcon,
  ShieldCheckIcon,
} from "@heroicons/react/20/solid";

import PublicHeader from "../components/PublicHeader";
import Footer from "../components/Footer";


const faqs = [
  {
    id: 1,
    question: "Are my entries private and secure?",
    answer:
      "Yes, your privacy and security are our top priorities. Your notes are encrypted, we do not look at your data, nor is your data used to train any AI. We follow industry best practices for data protection and regularly update our security measures to safeguard your personal information.",
  },
  {
    id: 2,
    question: "Is Echo free to use?",
    answer:
      "Yes, the Echo beta is completely free to use. We believe that everyone should have access to tools that help them reflect and grow. Eventually, we're planning to introduce premium features to enhance your journaling experience even further.",
  },
  {
    id: 3,
    question: "How does Echo use AI?",
    answer:
      "Echo leverages the GPT-4o large language model for transcription, recall, and insight generation. When you record your voice, the AI transcribes your entries accurately. You can ask the AI questions about your past reflections, and it will search through your entries to provide meaningful and insightful answers, making your journaling experience more interactive and engaging.",
  },
];

const posts = [
  {
    id: 1,
    title: 'Get unstuck when you\'re writing',
    href: '/writing',
    description:
      'Every writer gets stuck. Use Echo voice notes to clarify your thoughts faster and capture lightning when it strikes.',
    imageUrl:
      '/illustration-talking-notes-white-bg.png',
  },
  {
    id: 2,
    title: 'Chronicle your life',
    href: '/chronicle',
    description:
      'Pair your photos with voice notes to capture not just the image, but the full story. Relive your memories with vivid details.',
    imageUrl:
      'illustration-selfie-white-bg.png',
  },
]

export default function HomePage() {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home Page",
    });
  }, []);

  return (
    <div>
      <PublicHeader />

      {/* Hero */}
      <div className="relative isolate overflow-hidden bg-white px-6 py-40 sm:pt-80 lg:overflow-visible lg:px-8">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            aria-hidden="true"
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          >
            <defs>
              <pattern
                x="50%"
                y={-1}
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
          </svg>
        </div>
        <div className="mx-auto max-w-6xl flex flex-col lg:flex-row max-w-2xl lg:items-start">
          <div className="w-full pb-20 lg:pb-0 lg:w-[60%]">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-indigo-600">AI Notes App</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">You talk, we organize</h1>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  Transform your spoken thoughts into organized, actionable ideas. Whether you're brainstorming your next project, capturing fleeting moments, or simply trying to get unstuck, turn voice notes into structured text.
                </p>
                <a href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019" target="_blank" rel="noopener noreferrer">
                  <img
                    src="/app-store-button.png"
                    alt="Download on the App Store"
                    className="mt-8 w-32 mx-auto sm:mx-0"
                  />
                </a>
                <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <ul role="list" className="mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <MicrophoneIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                    <span>
                      <strong className="font-semibold text-gray-900">Voice-to-text.</strong> Quickly capture your thoughts by speaking naturally. Talk freely—Echo thrives on the messiness of raw thoughts.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <LightBulbIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                    <span>
                      <strong className="font-semibold text-gray-900">AI-powered.</strong> Echo uses GPT-4o to transcribe, organize, and synthesize your notes.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <ShieldCheckIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" />
                    <span>
                      <strong className="font-semibold text-gray-900">Private and secure.</strong> Rest assured, your notes are private. Your data is encrypted, we don't look at it, and it is not used to train any AI.
                    </span>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[40%]">
            <div className="px-12">
                <svg
                    viewBox="0 0 366 729"
                    role="img"
                    className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
                  >
                    <title>App screenshot</title>
                    <defs>
                      <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                        <rect width={316} height={684} rx={36} />
                      </clipPath>
                    </defs>
                    <path
                      fill="#4B5563"
                      d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                    />
                    <path
                      fill="#343E4E"
                      d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                    />
                    <foreignObject
                      width={316}
                      height={684}
                      transform="translate(24 24)"
                      clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                    >
                      <img src="/showcase-home.png" alt="" />
                    </foreignObject>
                </svg>
            </div>  
          </div>
        </div>
      </div>

      {/* Use-cases */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How to use Echo</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Echo effortlessly captures your thoughts through voice or text, then organizes and refines them using advanced AI, helping you turn scattered ideas into actionable notes with ease.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {posts.map((post) => (
            <article
              key={post.id}
              className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
            >
              <img alt="" src={post.imageUrl} className="absolute inset-0 -z-10 h-full w-full object-cover" />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <h3 className="mt-3 mb-2 text-lg font-semibold leading-6 text-white">
                <a href={post.href}>
                  <span className="absolute inset-0" />
                  {post.title}
                </a>
              </h3>
              <p className="text-base leading-7 text-gray-300">
                {post.description}
              </p>
            </article>
          ))}
        </div>
      </div>

      {/* FAQ section */}
      <div className="mx-auto max-w-5xl divide-y divide-gray-900/10 px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                {faq.question}
              </dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </dd>
            </div>
          ))}
        </dl>
      </div>      
      <Footer />
    </div>
  );
}
