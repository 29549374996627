const navigation = {
  main: [
    {
      name: "Product",
      href: "/",
    },
    {
      name: "About",
      href: "/about",
    },
    {
      name: "Help",
      href: "/help",
    },
    {
      name: "Terms of Service",
      href: "https://docs.google.com/document/d/1WjIEUbvqNzmTdiq8lalcZQqC-ByUii0KoY111sJ5YnE/edit?usp=sharing",
    },
    {
      name: "Privacy Policy",
      href: "https://docs.google.com/document/d/1d6TL3rQhfbVwKmZfZjsl1KfGjrwuX1MgrCMRPUeTIZE/edit?usp=sharing",
    },
  ],
};

export default function Footer() {
  return (
    <footer className="z-50 relative">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav
          className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a
                href={item.href}
                className="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2024 Verdai, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
