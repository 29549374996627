import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

const PublicHeader = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 flex items-center	">
            <span className="sr-only">Echo</span>
            <img className="h-8 w-auto" src="/logo192Black.png" alt="" />
            <span className="text-sm pl-4 font-semibold text-gray-900">
              Echo
            </span>
          </Link>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-center space-x-8">
          <Link
            to="/"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Product
          </Link>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              Use-cases
              <ChevronDownIcon aria-hidden="true" className="h-4 w-4 flex-none text-gray-700" />
            </Popover.Button>

            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 mt-3 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <Link
                    to="/writing"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Writing tool
                  </Link>
                  <Link
                    to="/chronicle"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Memory chronicle
                  </Link>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Link
            to="/about"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            About
          </Link>
          <Link
            to="/help"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Help
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
          <div className="ml-4">
            <a
              href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Download now <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Verdai</span>
              <img className="h-8 w-auto" src="/logo192Black.png" alt="" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Product
                </Link>
                <Popover className="relative">
                  <Popover.Button className="w-full text-left block py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Use-cases
                    <ChevronDownIcon className="h-5 w-5 ml-2 inline" aria-hidden="true" />
                  </Popover.Button>

                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="mt-2 w-full bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5">
                      <div className="py-1">
                        <Link
                          to="/writing"
                          className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
                        >
                          Writing tool
                        </Link>
                        <Link
                          to="/chronicle"
                          className="block px-4 py-2 text-base text-gray-700 hover:bg-gray-100"
                        >
                          Memory chronicle
                        </Link>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
                <Link
                  to="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About
                </Link>
              </div>
              <div className="py-6">
                <a
                  href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Download now <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default PublicHeader;
